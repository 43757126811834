import Swal from 'sweetalert2';
import i18n from '../i18n';

export const confirm = (
  {
    title = i18n.t('translation:general.operation.confirm-title') as string,
    text = i18n.t('translation:general.operation.confirm') as string,
    confirmButtonText = i18n.t('translation:general.buttons.delete') as string,
    cancelButtonText = i18n.t('translation:general.buttons.cancel') as string,
    onConfirm = () => void (0),
    onCancel = () => void (0),
    html = '',
    width = undefined,
    showCancelButton = true
  }) => {

  Swal.fire({
    title: title,
    text: text,
    icon: 'warning',
    html: html,
    width: width,
    showCancelButton: showCancelButton,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    buttonsStyling: false,
    heightAuto: false,
    customClass: {
      cancelButton: 'btn btn-light m-2',
      confirmButton: 'btn btn-primary m-2',
    },
  }).then((result) => {
    if (result.value) {
      onConfirm();
    } else {
      onCancel();
    }
  });
};

export const custom = (
  {
    title = i18n.t('translation:general.operation.confirm-title') as string,
    text = i18n.t('translation:general.operation.confirm') as string,
    confirmButtonText = i18n.t('translation:general.buttons.delete') as string,
    cancelButtonText = i18n.t('translation:general.buttons.cancel') as string,
    onConfirm = () => void (0),
    onCancel = () => void (0),
    html = '',
    width = undefined,
    showCancelButton = true,
    target = undefined || document.body
  }) => {

  Swal.fire({
    title: title,
    text: text,
    // icon: 'warning',
    html: html,
    width: width,
    showCancelButton: showCancelButton,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    buttonsStyling: false,
    heightAuto: false,
    customClass: {
      cancelButton: 'btn btn-light m-2',
      confirmButton: 'btn btn-primary m-2',
    },
    target: target
  }).then((result) => {
    if (result.value) {
      onConfirm();
    } else {
      onCancel();
    }
  });
};