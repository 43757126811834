import classNames from 'classnames';
import queryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { setTableToken } from '../../api/authService';
import * as menuApi from '../../api/menuApi';
import * as nomenclatorApi from '../../api/nomenclatorApi';
import * as orderApi from '../../api/orderApi';
import * as tableApi from '../../api/tableApi';
import ScreenLoader from '../../components/ScreenLoader';
import Badge from '../../components/bootstrap/Badge';
import Button from '../../components/bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import showNotification from '../../components/extras/showNotification';
import Icon from '../../components/icon/Icon';
import CartContext from '../../contexts/cartContext';
import useDarkMode from '../../hooks/useDarkMode';
import useFetch from '../../hooks/useFetch';
import useModal from '../../hooks/useModal';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { demoPagesMenu } from '../../menu';
import Cart from './modal/cart';

const Menu = () => {

	const { cartData, addProduct, setCartData } = useContext(CartContext);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const { t } = useTranslation(['menu', 'translation']);

	const location = useLocation()
	const navigate = useNavigate();
	const searchParams = queryString.parse(location.search);
	const qr = searchParams && searchParams.qr ? String(searchParams.qr) : "";
	setTableToken(qr);

	const { state: stateModalCart, open: openModalCart, setIsOpen: setIsOpenCart } = useModal();

	const [validateResponse, validating] = useFetch({ requestData: tableApi.validateTableUuid, deps: [qr] });
	const [order, setOrder] = useState(null);

	useEffect(() => {
		if (validateResponse && validateResponse.validToken) {
			console.log('setOrder', validateResponse.order);
			setOrder(validateResponse.order);
		} else {
			setOrder(null);
		}
		console.log('validateResponse.order', validateResponse.order)
	}, [validateResponse]);

	useEffect(() => {
		// @ts-ignore
		const orderStocks = order && order.orderStocks || [];
		if (setCartData) {
			// @ts-ignore
			setCartData({ id: order && order.id, orderStocks });
		}
	}, [order]);

	const isValidToken = (!validating && validateResponse && validateResponse.validToken === true) || false;
	const existsOrder = (!validating && validateResponse && validateResponse.validToken === true) || false;

	// useState()

	console.log('validating', validating)
	console.log('validateResponse', validateResponse)

	// @ts-ignore
	const [menu, loadingMenu] = useFetch({ requestData: isValidToken ? menuApi.getMenuFromTableContext : null, deps: [isValidToken], initialValue: { data: {} } });
	const [selectedCategory, setSelectedCategory] = useState<any>(null);
	const [subItems, setSubItems] = useState([]);

	const [paymentTypes, loadingPaymentTypes] = useFetch({ requestData: nomenclatorApi.getAllPaymentType, deps: [] });
	const [orderStatusTypes, loadingOrderStatusTypes] = useFetch({ requestData: nomenclatorApi.getAllOrderStatusType, deps: [] });

	// @ts-ignore
	const items = menu && menu.items || [];

	const handleCategorySelection = (index: number) => {
		const row = items[index] || null;
		setSelectedCategory(row && row.category || null);
		setSubItems(row && row.items || []);
	};

	const createOrderPayment = async () => {
		const payload = {
			"idOrder": cartData.id,
			"idPaymentType": 1,
		}

		try {
			// addProduct(item);

			const response = await orderApi.createOrderFromTableContext(payload);
			console.log('response', response)

			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='ShoppingCart' size='lg' className='me-1' />
					<span>Producto adicionado</span>
				</span>,
				`"OK" adicionado al pedido correctamente.`,
			);
		} catch (error: any) {
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='ShoppingCart' size='lg' className='me-1' />
					<span>Error</span>
				</span>,
				"No se pudo realizar la operación.",
			);
		}
		finally {

		}
	};

	return (
		<>
			<PageWrapper title={t("Tasks", { ns: 'menu' }) as string}>
				{/* <PageWrapper title={t(demoPagesMenu.task.text, { ns: 'menu' }) as string}> */}
				<Page container='fluid'>
					<div className='row h-100'>
						<div className='col-12 col-md-5'>

							<Card stretch={true}>
								<CardHeader borderSize={1}>
									<CardLabel icon={demoPagesMenu.stockGroup.subMenu.category.icon} iconColor='info'>
										<CardTitle>
											Categorías
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody className='task-responsive' isScrollable={true}>

									<Button
										isOutline={!darkModeStatus}
										color='dark'
										isLight={darkModeStatus}
										className={classNames('text-nowrap', 'me-1', {
											'border-light': !darkModeStatus,
										})}
										icon='Check'
										onClick={() => {
											openModalCart(1);
										}}
									/>

									<Button
										isOutline={!darkModeStatus}
										color='dark'
										isLight={darkModeStatus}
										className={classNames('text-nowrap', 'me-1', {
											'border-light': !darkModeStatus,
										})}
										icon='Check'
										onClick={createOrderPayment}
									/>


									{
										// @ts-ignore
										items.map((item, index) => (
											<Button
												key={index}
												className="w-100 my-1"
												color='light'
												isActive={selectedCategory && selectedCategory.id === item.category.id}
												onClick={() => { handleCategorySelection(index) }}>

												{item.category && item.category.name}

												<Badge
													className='mx-1'
													color="primary"
													rounded="circle">
													{item.items.length}
												</Badge>

											</Button>
											// <div key={index} onClick={}>{item.category && item.category.name}</div>
										))}
								</CardBody>
							</Card>
						</div>
						<div className='col-12 col-md-7'>
							<Card stretch={true}>
								<CardHeader borderSize={1}>
									<CardLabel icon={demoPagesMenu.stockGroup.subMenu.stock.icon} iconColor='info'>
										<CardTitle>
											Productos {
												// @ts-ignore
												`- ${selectedCategory && selectedCategory.name || ''}`}
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody className='task-responsive' isScrollable={true}>
									{
										// @ts-ignore
										subItems.map((item: any, index) => (
											<div key={index} className="w-100 my-1">{item && item.name}
												<Button
													isOutline={!darkModeStatus}
													color='dark'
													isLight={darkModeStatus}
													className={classNames('text-nowrap', 'mx-2', {
														'border-light': !darkModeStatus,
													})}
													icon='AddShoppingCart'
													onClick={() => {
														try {
															addProduct(item);
															showNotification(
																<span className='d-flex align-items-center'>
																	<Icon icon='ShoppingCart' size='lg' className='me-1' />
																	<span>Producto adicionado</span>
																</span>,
																`"${item.name}" adicionado al pedido correctamente.`,
															);
														} catch (error: any) {
															showNotification(
																<span className='d-flex align-items-center'>
																	<Icon icon='ShoppingCart' size='lg' className='me-1' />
																	<span>Error</span>
																</span>,
																"No se pudo realizar la operación.",
															);
														}
														finally {

														}
													}}
												/>
											</div>
										))
									}

									{selectedCategory && !subItems.length &&
										<div>No existen productos</div>}
								</CardBody>
							</Card>
						</div>
					</div>
				</Page>
			</PageWrapper>

			<Cart
				isOpen={stateModalCart.isOpen}
				setIsOpen={setIsOpenCart}
				data={stateModalCart.data}
			// onSuccessSubmit={() => { fetchData({ filters }); }}
			/>

			<ScreenLoader show={loadingMenu} />
		</>
	);
};

export default Menu;